<template>
  <div class="block">
    <img class="bg" :src="item.content" />
    <div class="text" v-if="type == 0">
      <ul class="data">
        <li>
          <!-- <div
            class="icon-home2 icon_default"
            :style="`color:${item.color}`"
          ></div> -->
          <!-- <img src="@image/h5/create_home2.png" /> -->
          <p :style="`color:${item.color}`">
            {{ form.name || "十九匠商城有限公司" }}
          </p>
        </li>
        <li>
          <!-- <img src="@image/h5/create_phone2.png" /> -->
          <!-- <div
            class="icon-mobile icon_default"
            :style="`color:${item.color}`"
          ></div> -->

          <p :style="`color:${item.color}`">{{ form.phone || 19999999999 }}</p>
        </li>

        <li>
          <!-- <div
            class="icon-location icon_default"
            :style="`color:${item.color}`"
          ></div> -->
          <!-- <img src="@image/h5/create_address2.png" /> -->
          <!-- <p>{{ TextChangeLine(form.address, 1) }}</p> -->
          <p :style="`color:${item.color}`">
            {{ TextChangeLine(form.address, 1) }}
          </p>
        </li>
        <li>
          <!-- <img src="@image/h5/create_address2.png" style="opacity: 0" /> -->
          <!-- <div class="icon-location icon_default" style="opacity: 0"></div> -->

          <p :style="`color:${item.color}`">
            {{ TextChangeLine(form.address, 2) }}
          </p>
          <!-- <p>{{ TextChangeLine(form.address, 2) }}</p> -->
        </li>
      </ul>

      <template>
        <img v-if="form.businessCardImage" :src="form.businessCardImage" />
        <img
          v-else
          src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021111215250242332.jpg"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
    form: {},
    color: {
      default: "#fff",
    },
    type: {
      default: 0,
    },
    zoom: {
      default: 1,
    },
  },
  methods: {
    TextChangeLine(item, mode) {
      if (mode == 1) {
        return item.substring(0, 18);
      } else {
        return item.substring(18, item.length);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
@import "../style/style.css";
$bs: 2;
.create_ul {
  // width: rem(550);
  // margin: 0 auto;
  position: relative;
}
.block {
  position: relative;
  transform: scale(0.93);
}
.bg {
  display: block;
  width: rem(550 * $bs);
}
.text {
  box-sizing: border-box;
  position: absolute;
  background: rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(rem(8.8));
  width: 100%;
  // height: rem(130);
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: rem(10 * $bs);
  padding-bottom: rem(14 * $bs);
  > img {
    display: block;
    width: rem(92 * $bs);
    height: rem(92 * $bs);
  }
  .data {
    flex: 1;
    margin-top: rem(-10 * $bs);
    margin-right: rem(12 * $bs);
    li {
      display: flex;
      align-items: center;

      &:last-of-type {
        margin-top: rem(-8 * $bs);
      }
      p {
        @include ell;
        width: 100%;
        // text-align: right;
        // width: rem(560);
        color: #ffffff;
        font-size: rem(21 * $bs);
        text-align: right;
      }
      img {
        width: rem(22 * $bs);
        // height: rem(22);
        margin-right: rem(6 * $bs);
      }
    }
  }
}

.icon_default {
  width: rem(22 * $bs);
  height: rem(22 * $bs);
  color: #fff;
  font-size: rem(20 * $bs);
  margin-right: rem(6 * $bs);
}
</style>
