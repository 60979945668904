<template>
  <div>
    <oLoading :num="loadingTime" v-if="loadingBoo"></oLoading>
    <div class="fixed_store" @click="actionShowFoo" v-if="type == 0">
      <svg-icon iconClass="create_edit2"> </svg-icon>
      <p>编辑店家信息</p>
    </div>

    <swiper
      ref="mySwiper"
      :options="{
        ...leftTitle,
      }"
      class="swiper-container-left"
      style="z-index: 99;"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="(item, index) in list"
        style="width:100%"
        :key="index"
      >
        <div class="create">
          <div class="create_ul">
            <template v-if="UserArrCreareImage.length == 0">
              <oSolid :item="item" :form="form" :type="type"></oSolid>
            </template>

            <template v-else>
              <img :src="UserArrCreareImage[index]" class="showImg" />
            </template>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <div class="fixed_tool">
      <div class="li">
        <svg-icon iconClass="create_image"> </svg-icon>
        <p @click="$router.push({ name: 'generateList' })">海报</p>
      </div>
      <div class="solid"></div>
      <div class="li">
        <svg-icon iconClass="create_doc"> </svg-icon>
        <p @click="textShow = true">文案</p>
      </div>
    </div>

    <van-popup
      v-model="actionShow"
      round
      position="bottom"
      closeable
      class="input_popup"
      :preview-full-image="false"
      :preview-image="false"
    >
      <van-field
        v-model="form.name"
        label="工作室/公司/姓名"
        placeholder="请输入工作室/公司/姓名"
        maxlength="13"
      />
      <van-field
        v-model="form.phone"
        label="手机号码"
        placeholder="请输入手机号码"
        maxlength="11"
      />
      <van-field
        v-model="form.address"
        label="地址"
        placeholder="请输入地址"
        maxlength="26"
      />
      <div class="input_upload">
        <p class="input_text">个人二维码</p>
        <div class="input_solid">
          <template>
            <img
              src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021111310382636845.png"
              class="image"
              v-if="form.businessCardImage"
            />
            <img :src="form.businessCardImage" class="image" v-else />
          </template>
          <van-uploader
            class="uploader"
            multiple
            :max-count="1"
            :after-read="afterRead"
          >
          </van-uploader>
        </div>
      </div>
      <div class="input_save">
        <van-button type="primary" round @click="saveUserForm()"
          >保存资料</van-button
        >
      </div>
    </van-popup>
    <!-- -->
    <div v-if="SolidShow">
      <oSolid2
        :type="type"
        :item="item"
        class="mainImg2"
        ref="mainImg3"
        v-for="(item, index) in list"
        :key="index"
        :form="form"
        color="red"
      ></oSolid2>
    </div>

    <oClip :boo.sync="clipBoo" :clipUrl="clipUrl" @enter="enterClip"></oClip>

    <div class="fixed_jt" v-if="list && list.length > 1">
      <svg-icon
        iconClass="create_left"
        v-if="swiperIndex != 0"
        class="svg1"
      ></svg-icon>
      <svg-icon
        iconClass="create_right"
        v-if="swiperIndex != list.length - 1"
        class="svg2"
      ></svg-icon>
    </div>

    <van-popup
      v-model="textShow"
      position="right"
      get-container="body"
      closeable
      :style="{ height: '100%', width: '100%' }"
      close-icon-position="top-left"
    >
      <oText></oText>
    </van-popup>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import oText from "./doc.vue";
import html2canvas from "html2canvas";
import oClip from "./components/clip.vue";
import { Popup, Field, Uploader, Button, Toast, Empty, Notify } from "vant";
import oLoading from "../createHB/components/loading.vue";
import oSolid from "./components/solid.vue";
import oSolid2 from "./components/solid2.vue";
import "swiper/css/swiper.css";
import { get, post } from "@get/http";
import { upload, getOss } from "@get/upload.js";
import { collectInfo } from "./js/index.js";
export default {
  components: {
    oText,
    oClip,
    oLoading,
    oSolid,
    oSolid2,
    Swiper,
    SwiperSlide,
    vanPopup: Popup,
    vanField: Field,
    vanUploader: Uploader,
    vanButton: Button,
    vanPopup: Popup,
    vanEmpty: Empty,
  },
  data() {
    return {
      textShow: false,
      UserArrCreareImage: [],
      SolidShow: false, //生成控件Boo
      list: [],
      clipBoo: false,
      clipUrl: "",
      leftTitle: {
        slidesPerView: "auto",
        // slidesPerView: 4,
        centeredSlides: true,
      },
      loadingTime: 0,
      loadingTImer: null,
      loadingBoo: false,
      localStorageKey: "19jiang_create_info",
      form: {
        name: "",
        phone: "",
        address: "",
        businessCardImage: "",
      },
      fileList: [],
      actionShow: false,
      type: 0,
      swiperIndex: 0,
    };
  },
  created() {
    this.type = this.$route.query.type || 0;
    let list = sessionStorage["19jiang_create_mg"];
    if (this.$route.query.type == "undefined") {
      this.$router.push({
        name: "generateList",
      });
    }
    if (list) {
      this.list = JSON.parse(list);
    } else {
      this.$router.push({
        name: "generateList",
      });
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    if (this.list?.length != 0) {
      this.getDataInit();
    }
  },
  methods: {
    onSlideChange() {
      let swiper = this.swiper;
      this.swiperIndex = swiper.activeIndex;
    },
    actionShowFoo() {
      this.actionShow = true;
      getOss();
    },
    async enterClip(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });

      try {
        let data = await upload(file);
        this.form.businessCardImage = `https://image.shop.19jiang.cn${data.name}`;
        Toast("上传成功");
      } catch (error) {
        Toast("上传失败");
      }
    },
    async getDataInit() {
      let lo = localStorage[`${this.localStorageKey}`];
      if ((lo && lo.length != 0) || this.type == 1) {
        if (this.type == 1) {
          Notify({ type: "success", message: "长按海报保存到相册" });
          return false;
        }
        if (this.type == 0) {
          lo = JSON.parse(lo);
          this.form = lo;
        }
        this.SolidShow = true;
        this.UserArrCreareImage = [];
        this.loadingBoo = true;
        Toast.loading({
          message: "生成中...",
          forbidClick: true,
          overlay: true,
          duration: 0,
          getContainer: "#app",
        });

        this.loadingBoo = true;
        setTimeout(async () => {
          let domArr = document.querySelectorAll(".mainImg2");
          for (let index = 0; index < domArr.length; index++) {
            let url = await this.toImage(domArr[index]);
            this.loadingTime = (100 / domArr.length) * (index + 1);
            this.UserArrCreareImage.push(url);
          }

          // this.finalShow = false;
          this.SolidShow = false;

          if (this.list.length == this.UserArrCreareImage.length) {
            Toast.clear();
            this.loadingBoo = false;
            this.actionShow = false;
            Toast({
              message: "长按海报保存到相册",
              duration: 3000,
            });
            this.loadingTime = 0;
          }
        }, 1000);
      } else {
        Toast.clear();
        Notify({ type: "danger", message: "填写店家信息后自动生成海报！" });
      }
    },

    async toImage(dom) {
      try {
        return new Promise((ro, rj) => {
          (window.html2canvas || html2canvas)(dom, {
            async: true,
            backgroundColor: null,
            useCORS: true,
            scale: window.devicePixelRatio || 1,
            // scrollY: 0,
            // scrollX: 0,
          })
            .then((canvas) => {
              let url = canvas.toDataURL("image/png");
              ro(url);
            })
            .catch((error) => {});
        });
      } catch (error) {
        console.log(error, "报错");
      }
    },

    gtouchstart() {
      alert("需要上传资料生成专属图片后才可以保存哦~");
    },
    async saveUserForm() {
      if (!this.form.name) {
        Toast("请输入主体");
        return false;
      }
      if (!this.form.phone) {
        Toast("请输入手机号码");
        return false;
      }
      if (!this.form.address) {
        Toast("请输入地址");
        return false;
      }
      if (!this.form.businessCardImage) {
        Toast("请上传个人二维码名片");
        return false;
      }
      localStorage[`${this.localStorageKey}`] = JSON.stringify(this.form);
      await collectInfo(0, 0, this.form);

      // this.getDataInit();
      window.location.reload();
    },
    async afterRead(file) {
      this.clipUrl = file.content;
      this.clipBoo = true;
    },
    afterRead2(file) {
      console.log(file);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
::v-deep {
  .gt-tab {
    margin-top: rem(8);
    margin-left: rem(60);
  }
}
.swiper-container-left {
  padding-top: rem(104);
  position: relative;
  // background: #fff;
  // overflow: hidden;
  width: rem(750);
  // height: rem(890);
  .swiper-slide {
    // height: auto;
    width: 78% !important;

    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // height: rem(890);
    // margin: 0 auto;
    .finalImg {
      padding: 0 !important;
      .show {
        width: 100%;
      }
    }
  }
}

.fixed_store {
  z-index: 999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(270);
  height: rem(64);
  box-sizing: border-box;
  background: #fff;
  border: rem(2) solid #ff4848;
  border-radius: rem(72);
  font-size: rem(30);
  top: rem(16);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ff4848;
  svg {
    margin-right: rem(12);
  }
}

.fixed_tool {
  position: fixed;
  width: rem(362);
  height: rem(96);
  bottom: rem(48);
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px rem(8) rgba(0, 0, 0, 0.1);
  border-radius: rem(48);
  display: flex;
  align-items: center;
  justify-content: center;
  .solid {
    width: rem(2);
    height: rem(40);
    background: #e5e5e5;
  }
  .li {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: rem(40);
      height: rem(40);
      margin-right: rem(8);
    }
    color: #222222;
    font-size: rem(30);
  }
}

.input_solid {
  position: relative;
  .image {
    width: rem(160);
    display: block;

    height: rem(160);
  }
  .uploader {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 99999;
  }
}
.input_upload {
  width: 6.2 em;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  // font-size: rem(22);
  background-color: #fff;
  align-items: center;
}
.input_text {
  color: #646566;
  margin-right: rem(40);
}
.input_save {
  width: 100%;

  ::v-deep {
    .van-button {
      display: block;
      width: rem(400);
      margin: rem(10) auto rem(20);
      // margin: 0 auto;
    }
  }
}

.mainImg2 {
  position: absolute;
  z-index: -1;
  top: 0;
  width: auto !important;
  left: 0;
}

.showImg {
  width: 100%;
  transform: scale(0.93);
}

.fixed_jt {
  svg {
    width: rem(44);
    height: rem(44);
    position: fixed;
    z-index: 99;
    top: rem(517);
  }
  .svg1 {
    left: rem(32);
  }
  .svg2 {
    right: rem(32);
  }
}
</style>
