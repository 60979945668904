<template>
  <div>
    <div id="clipArea" v-show="boo2">
      <div id="clipBtn">裁剪</div>
      <div id="cancel" @click="boo2 = false">取消</div>
    </div>
  </div>
</template>

<script>
import PhotoClip from "photoclip";
export default {
  data() {
    return {
      boo2: false,
    };
  },
  props: {
    boo: {
      defalut: false,
    },
    clipUrl: {},
  },
  watch: {
    boo() {
      if (this.boo == true) {
        this.clip();
      }
    },
  },
  mounted() {
    this.clip();
  },
  methods: {
    base64ChangeFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    clip() {
      var that = this;
      var ww = 500;
      var hh = 500;
      //do something after mounting vue instance
      var pc = new PhotoClip("#clipArea", {
        outputQuality: 1,
        //adaptive: ['60%', '80%'],
        size: [ww / 2, hh / 2],
        outputSize: [ww, hh],
        img: that.clipUrl,
        // file: "#file",
        ok: "#clipBtn",
        // img: 'img/mm.jpg',

        loadStart: function(data) {
          // console.log("开始读取照片", data);
        },

        loadComplete: function(data) {
          // console.log("照片读取完成", data);
          that.boo2 = true;
          // that.$refs.fileVal.value = "";
        },

        done: function(dataURL) {
          // console.log(dataURL);
          that.boo2 = false;

          that.$emit("enter", {
            file: that.base64ChangeFile(
              dataURL,
              `${new Date().getTime()}${Math.round(Math.random() * 1000000)}`
            ),
          });
          // console.log(dataURL);
        },

        fail: function(msg) {
          console.log(msg);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#clipArea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9998;
}

#clipBtn,
#cancel {
  width: rem(220);
  height: rem(75);
  position: absolute;
  line-height: rem(75);
  left: 0;
  right: 0;
  text-align: center;
  font-size: rem(30);
  margin: auto;
  // display: none;
  z-index: 9999;
  color: #fff;
}

#clipBtn {
  top: rem(1000);
  // background: url("../../../assets/img/jiequ_1.png") no-repeat;
  background-size: cover;
  background: #07c160;
}

#cancel {
  // background: url("../../../assets/img/quxiao_1.png") no-repeat;
  background-size: cover;
  top: rem(1100);
  background: #ee0a24;
}
</style>
