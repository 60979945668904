<template>
  <div>
    <van-empty
      :description="errorText"
      v-if="errorText && errorText.length != 0"
      image="error"
    >
      <van-button round class="bottom-button " @click="$router.go(-1)"
        >返回</van-button
      >
      <van-button
        round
        type="primary"
        class="bottom-button"
        @click="$router.push({ name: 'HomeIndex' })"
        >回到首页</van-button
      >
    </van-empty>
    <div class="doc" v-else>
      <ul class="gt-tab">
        <li
          v-for="item in tabOption"
          :key="item.class_id"
          @click="clickSetTab(item.class_id)"
        >
          <span :class="item.class_id == tabCur ? 'act' : ''">{{
            item.title
          }}</span>
          <div
            :style="`background : ${item.class_id == tabCur ? '#ff4848' : ''}`"
          ></div>
        </li>
      </ul>

      <div class="ul_van">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <div style="height:10px;"></div>
          <ul class="doc_ul">
            <li
              v-for="(item, index) in list"
              :key="item.id"
              :style="`background:${randomColor(index)}`"
            >
              <pre v-html="item.content"></pre>
              <div class="btn" @click="doCopy(item.content)">
                <svg-icon iconClass="create_copy"> </svg-icon>
                <p>复制文案</p>
              </div>
            </li>
          </ul>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, List, Empty, Button } from "vant";
import { get, post } from "@get/http";
import { collectInfo } from "./js/index.js";
export default {
  components: {
    vanList: List,
    vanEmpty: Empty,
    vanButton: Button,
  },
  data() {
    return {
      errorText: "",
      tabCur: 0,
      tabOption: [],
      pageSize: 20,
      page: 1,
      loading: false,
      finished: false,
      list: [],

      bgColor: ["#FEF4F3", "#FDEFF4", "#F8F0F9", "#EFF8FE", "#F7FBF2"],
    };
  },
  methods: {
    clickSetTab(item) {
      this.list = [];
      this.tabCur = item;
      this.page = 1;
      this.loading = true;
      this.finished = false;
      Toast.loading({
        message: "加载分类中..",
        forbidClick: true,
        duration: 0,
      });
      this.getData();
      collectInfo(1, item);
      Toast.clear();
    },
    async getList() {
      let data = await post(
        "/v3.MemberCardPoster/getClass",
        {},
        { result: true, toast: false, resultKey: "all" }
      );
      if (data.code == 10000) {
        this.tabOption = data.result.list.sort((a, b) => {
          if (a.sort > b.sort) {
            return 1;
          }
          if (a.sort < b.sort) {
            return -1;
          }
          return 0;
        });

        if (this.tabOption && this.tabOption.length != 0)
          this.tabCur = this.tabOption[0].class_id;
        this.getData();
      } else {
        this.errorText = data.message;
      }
    },
    onLoad() {
      this.getData();
    },
    async getData() {
      let data = await post("/v3.MemberCardPoster/getList", {
        params: {
          class_id: this.tabCur,
          type: 1,
          time: this.timeValue,
          pageSize: this.pageSize,
          page: this.page,
        },
      });

      Toast.clear();
      if (data.list.length == 0) {
        this.finished = true;
        return false;
      }

      this.list.push(...data.list);
      this.page++;
      this.loading = false;
      if (data.list.length < this.pageSize) {
        this.finished = true;
      }
    },

    randomColor(index) {
      return this.bgColor[Math.floor(Math.random() * 5)]; //随机;
      return this.bgColor[index % 5]; //顺序
    },
    doCopy: function(copy) {
      this.$copyText(copy).then(
        function(e) {
          Toast("十九匠商城:已复制");
        },
        function(e) {
          Toast("十九匠商城:复制失败");
        }
      );
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.doc_ul {
  margin-bottom: rem(100);
  li {
    padding: rem(24) rem(30);
    box-sizing: border-box;
    width: rem(690);
    margin: rem(16) auto 0;
    border-radius: rem(16);
    > pre {
      color: #222222;
      font-size: rem(34);
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    .btn {
      width: rem(204);
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(56);
      background: rgba(255, 255, 255, 0.1);
      border: rem(2) solid #dddddd;
      box-sizing: border-box;
      border-radius: rem(32);
      margin-top: rem(24);
      svg {
        width: rem(36);
        height: rem(36);
        margin: rem(8);
      }
      > p {
        color: #666666;
        font-size: rem(28);
      }
    }
  }
}

.gt-tab {
  display: flex;
  height: rem(105);
  align-items: center;
  width: rem(750);
  padding: 0 rem(30);
  overflow-x: auto;
  // flex-wrap: wrap;
  white-space: nowrap;

  background: #fff;
  li {
    position: relative;
    padding: 0 rem(20);

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: rem(160);
    }

    span {
      color: #999999;
      font-size: rem(32);
    }

    .act {
      color: #222222;
      font-weight: bold;
      font-size: rem(32);
    }

    div {
      width: rem(36);
      height: rem(6);
      border-radius: rem(3);
      margin: rem(2) auto 0;
    }
  }
}

.doc {
  width: 100%;
  overflow: hidden;
}

.bottom-button {
  display: block;
  margin: 0 auto;
  margin-bottom: rem(30);
  width: rem(300);
}
</style>
